.imgAnaAnnotate .title2 {
    color: white;
    background-color: rgb(47, 44, 44);
    border-radius: 5px;
    margin: 2px;
    padding: 2px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 10px;
}

.imgAnaAnnotate .initDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin: 10px;
}

.imgAnaAnnotate .imgCont{
    width: 100%;
    height: 72vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.imgAnaAnnotate .imgCont .imgBox{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}