.chatbotScreen .imgWindow {
    height: 83vh;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    overflow-y: auto;
}

.chatbotScreen .imgWindow .initDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.chatbotScreen .imgWindow .carousel-indicators button{
    background-color: #bbb;
    width: 8px;
    height: 8px;
    margin: 2px;
    border: none;
    padding: unset;
    border-radius: 50%;
    display: inline-block;
    outline: unset;
}

.chatbotScreen .imgWindow .carousel-indicators button.active{
    outline: unset;
    background-color: #1e1b1b;
}

.chatbotScreen .imgWindow .carousel-control-next, .carousel-control-prev {
    margin-top: auto;
    margin-bottom: auto;
    height: 200px;
}


.chatbotScreen .imgWindow .imgCont{
    width: 100%;
    height: 63vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.chatbotScreen .imgWindow .imgCont .imgBox{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}

.chatbotScreen .chatWindow {
    height: 83vh;
}

.chatbotScreen .chatWindow .initDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.chatbotScreen .chatWindow .imgCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.chatbotScreen .chatWindow .imgCont .imgBox{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}

.chatbotScreen .chatWindow .chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chatbotScreen .chatWindow .chat-container .chat-box {
    padding: 5px;
    flex-grow: 1;
    overflow-y: auto;
    border-radius: 10px;
    padding-bottom: 10px; 
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}
  
.message {
    padding: 10px;
    border-radius: 5px;
    max-width: 70%;
    word-wrap: break-word;
    margin-bottom: 10px;
}
  
.user {
    background-color: #e2f7fe;
    align-self: flex-end;
}
  
.bot {
    background-color: #f0f0f0;
    align-self: flex-start;
}
  
.chatbotScreen .chatWindow .chat-container .input-area {
    margin-top: auto;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;
}
  
.chatbotScreen .chatWindow .chat-container .input-group-btn {
    padding-left: 10px;
}

.chatbotScreen .chatWindow .chat-container .histBtnCont {
    /* margin: 8px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbotScreen .imgWindow .form-check {
    margin-bottom: unset;
}

.chatbotScreen .imgWindow .form-check-input {
    margin-top: 0.187rem;
    background-color: #5a5d60;
}

.chatbotScreen .imgWindow .feedbackBtnCont {
    padding: 1px;
    border-radius: 5px;
    background-color: #6c757d;
    color: white;
}

.chatbotScreen .imgWindow .promptCont {
    margin-top: 5px;
    padding: 3px 1px;
    border-radius: 5px;
    background-color: #6c757d;
    color: white;
}

.chatbotScreen .chatWindow .selector {
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    color: #dfe4e9;
    background-color: #525252;
    margin-bottom: 5px;
}

.chatbotScreen .chatWindow .form-select {
    display: unset;
    width: 100%;
    color: #adb5bd;
    background-color: #212529;
    border: none;
    /* color: #dad7d7; */
    /* background-color: #393636; */
}

@media only screen and (max-width: 800px) {
    .chatbotScreen .imgWindow {
        height: 75vh;
    }
    .chatbotScreen .chatWindow {
        height: 30vh;
    }
    .chatbotScreen .imgWindow .imgCont{
        height: 56vh;
    }
}

@media only screen and (max-width: 500px) {
    .chatbotScreen .chatWindow .chat-container {
        height: unset;
        flex-direction: column-reverse;
    }
    .chatbotScreen .chatWindow .chat-container .chat-box {
        margin-top: 10px;
    }
    .chatbotScreen .chatWindow .chat-container .histBtnCont {
        display: none;
    }
}