.imgAnaAnnotateCard {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin: 10px;
}

.imgAnaAnnotateCard .imgWindow {
    border-radius: 10px;
    /* background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2); */
    /* padding: 10px; */
}

.imgAnaAnnotateCard .imgWindow .imgCont{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.imgAnaAnnotateCard .imgWindow .imgCont .imgBox{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
    border-radius: 10px;
}

.imgAnaAnnotateCard .secondWindow {
    height: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    overflow-y: auto;
}

.imgAnaAnnotateCard .secondWindow .title2 {
    color: white;
    background-color: rgb(47, 44, 44);
    border-radius: 5px;
    margin: 2px;
    padding: 2px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
}

.imgAnaAnnotateCard .secondWindow .btnsDiv {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 2px;
}
.imgAnaAnnotateCard .secondWindow .btnsDiv .btn {
    margin: 2px;
    padding: 0px 5px;
    border-radius: 3px;
    flex: 13% 1;
    font-size: 14px;
}