div .ShopifyPortal{
  margin-top: 10px;
}

div .ShopifyPortal .content {
  max-height: 80%;
  display: flex;
  margin: auto;
  flex-direction: column;
}

div .ShopifyPortal .selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: #dfe4e9;
  background-color: #525252;
  padding: 8px 2px;
}

.ShopifyPortal .product-view {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  height: 60vh;
}

.ShopifyPortal .upload {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #dfe4e9;
  background-color: #525252;
  text-wrap: nowrap;
  padding: 10px 5px;
}

.ShopifyPortal .carousel-control-next-icon {
  background-image: url("../../assets/icons/single-arrow-right.svg");
  height: 32px;
  width: 32px;
  position: absolute;
  bottom: 30%;
}

.ShopifyPortal .carousel-control-prev-icon {
  background-image: url("../../assets/icons/single-arrow-left.svg");
  height: 32px;
  width: 32px;
  position: absolute;
  bottom: 30%;
}

.ShopifyPortal .carousel-control-next, .ShopifyPortal .carousel-control-prev {
  height: 15% !important;
  margin-top: auto;
  margin-bottom: auto;
}

.ShopifyPortal .reload {
  position: absolute;
  top: 5px;
  left: 5px;
}

.ShopifyPortal .environment {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: #dfe4e9;
  background-color: #525252;
  text-wrap: nowrap;
  padding: 10px 5px;
}

.ShopifyPortal .alert{
  border: 2px solid gray;
  max-width: 500px;
  height: 50px;
  text-align: center;
  position: absolute;
  top: 5px;
  margin: auto;
  right: 0;
  left: 0;
}

.ShopifyPortal .form-select {
  display: unset;
  width: 100%;
  color: #adb5bd;
  background-color: #212529;
  border: none;
  /* color: #dad7d7; */
  /* background-color: #393636; */
}

.ShopifyPortal label {
  margin: unset;
  margin: 0px 5px 0px 8px;
}

.ShopifyPortal .fileInput {
  margin: 0px 5px;
  color: #adb5bd;
  background-color: #212529;
  border: none;
  display: inline;
}

.ShopifyPortal .envBtn {
  position: absolute;
  right: 2px;
}

.ShopifyPortal .carouselCont {
  width: 50%;
  margin:0px 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShopifyPortal .carouselImg {
  width: 300px !important;
  height: 300px !important;
  margin: auto;
}

.ShopifyPortal .canvas {
  width: 50% !important;
  margin:0px 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 500px) {
  div .ShopifyPortal .selector, .ShopifyPortal .upload, .ShopifyPortal .environment
  {
    flex-direction: column;
    align-items: unset;
  }
  .ShopifyPortal label {
    margin: 2px 5px 2px 8px;
  }
  .ShopifyPortal .envBtn {
    position: unset;
    right: unset;
  }
  .ShopifyPortal .product-view {
    flex-direction: column;
  }
  .ShopifyPortal .carouselCont {
    width: 100%;
    margin:5px;
  }
  .ShopifyPortal .canvas {
    width: 100% !important;
    margin:5px;
  }
}