.data-export-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items:flex-start;
  justify-content: space-between;
}

.expandable-section {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  margin: 8px;
  padding: 16px;
  flex: 30%;
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
}

.expandable-section:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
}

.section-title {
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
}


.link-list {
  margin-top: 12px;
}


.download-link {
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
  transition: color 0.3s;
}

.download-link:hover {
  color: #0056b3;
}
