.annotateCont {
    margin: 10px;
    padding: 10px;
}
.annotateCont .list {
    list-style: none;
    margin: unset;
}

.annotateCont .list li {
    margin: 15px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(46, 44, 44, 0.2);
    font-size: 1.1rem;
    font-weight: bold;
    transition: box-shadow 0.3s, transform 0.3s, color 0.3s, font-size 0.3s;
    cursor: pointer;
}
.annotateCont .list a {
    text-decoration: none !important;
    color: inherit;
    text-transform: capitalize;
}

.annotateCont .list li:hover {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
    color: red;
    font-size: 1.2rem;
}

.annotateCont .initDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.annotateCont .imgCont{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.annotateCont .imgCont .imgBox{
    border-radius: 10px;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}