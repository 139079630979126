.annotateCard {
    margin: 5px;
    padding: 10px;
}

.annotateCard .title {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    padding: 5px;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.annotateCard .imgWindow {
    height: 75vh;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    overflow-y: auto;
}

.annotateCard .imgWindow .initDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.annotateCard .imgWindow .carousel-indicators{
    bottom: -10px;
}
.annotateCard .imgWindow .carousel-indicators button{
    background-color: #bbb;
    width: 8px;
    height: 8px;
    margin: 2px;
    border: none;
    padding: unset;
    border-radius: 50%;
    display: inline-block;
    outline: unset;
}

.annotateCard .imgWindow .carousel-indicators button.active{
    outline: unset;
    background-color: #1e1b1b;
}

.annotateCard .imgWindow .carousel-control-next, .carousel-control-prev {
    margin-top: auto;
    margin-bottom: auto;
    height: 200px;
}


.annotateCard .imgWindow .imgCont{
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.annotateCard .imgWindow .imgCont .imgBox{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}

.annotateCard .secondWindow {
    height: 75vh;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    overflow-y: auto;
}

.annotateCard .secondWindow .initDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.annotateCard .secondWindow .imgCont{
    width: 100%;
    height: 72vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.annotateCard .secondWindow .imgCont .imgBox{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}

.annotateCard .secondWindow .histBtnCont {
    width: 100%;
    /* position: absolute; */
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.annotateCard .secondWindow .dressDes {
    width: 100%;
    background-color: antiquewhite;
    margin-top: 10px;
}

.annotateCard .secondWindow .histBtnCont .pageNoCont{
    border-radius: 10px;
    padding: 5px 10px;
    background-color: aliceblue;
}

.annotateCard .secondWindow .histBtnCont .pageNoCont .pageIn{
    border: none;
    padding: 5px;
    width: 80px;
    text-align: center;
    border-radius: 10px;
    background-color: #bad2e8;
    appearance: none;
}

.pageNoCont input[type=number]::-webkit-inner-spin-button, 
.pageNoCont input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.annotateCard .secondWindow .title2 {
    color: white;
    background-color: rgb(47, 44, 44);
    border-radius: 5px;
    margin: 2px;
    padding: 2px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
}

.annotateCard .secondWindow .btnsDiv {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 2px;
}
.annotateCard .secondWindow .btnsDiv .btn {
    margin: 2px;
    padding: 0px 5px;
    border-radius: 3px;
    flex: 13% 1;
    font-size: 14px;
}

.annotateCard .secondWindow .selector {
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    color: #dfe4e9;
    background-color: #525252;
}

.annotateCard  .form-select {
    display: unset;
    width: 100%;
    color: #adb5bd;
    background-color: #212529;
    border: none;
    /* color: #dad7d7; */
    /* background-color: #393636; */
}
  
.annotateCard .secondWindow label {
    margin: unset;
    margin: 0px 5px 0px 8px;
}
  
.annotateCard .secondWindow .fileInput {
    margin: 0px 5px;
    color: #adb5bd;
    background-color: #212529;
    border: none;
    display: inline;
}

@media only screen and (max-width: 800px) {
    .annotateCard .imgWindow {
        height: 60vh;
    }
    .annotateCard .secondWindow {
        height: unset;
    }
    .annotateCard .imgWindow .imgCont{
        height: 56vh;
    }
    .annotateCard .secondWindow .histBtnCont {
        position: unset;
        flex-wrap: wrap;
    }
    .annotateCard .secondWindow .histBtnCont .pageNoCont{
        margin: 5px;
        position: unset;
    }
}