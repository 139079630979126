.config-container{
    /* background-color: red; */
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    padding: 2vh 4vw;
    gap: 20px;
}

.config-container .config-header{
    /* border: 1px solid black; */
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: rgb(205, 205, 205);
    padding: 1vh 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.config-questions{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding-bottom: 20px;
}

.config-questions .qo{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid gray;
}

.config-questions .qo .question{
    display: flex;
    padding: 1vh 2vw;
    align-items: center;
    justify-content: space-between;
}

.config-questions .qo .question .add-q{
    background-color: rgb(205, 205, 205);
    padding: 2.5px 7.5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.config-questions .qo .question .add-q span{
    font-size: 13px;
}

.qo .options-2{
    display: flex;
    padding: 1vh 3vw;
    flex-direction: column;
    gap: 10px;
}

.qo2 .options-2{
    display: flex;
    padding: 1vh 3vw;
    flex-direction: column;
    gap: 10px;
}

.actions{
    display: flex;
    gap: 5px;
}

.question-2{
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.options-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.option-add-container{
    display: flex;
    gap: 10px;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
}

.option-add-container .option{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 5px;
    background-color: rgb(102, 170, 102);
    color: white;
    padding: 2px 4px;
    border-radius: 5px;
}

.option-add-container .option span{
    font-size: 12px;
}

.qo2{
    display: flex;
    flex-direction: column;
}

.options .option{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 5px;
    background-color: rgb(102, 170, 102);
    color: white;
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 12px;
}

.qo .options{
    display: flex;
    padding: 1vh 3vw;
    /* flex-direction: column; */
    gap: 10px;
}


.pointer{
    cursor: pointer;
}